.contentWrapper {
    text-align: center;
}

.description {
    margin-right: 10px;
    margin-left: 10px;
}

.descriptionName {
    font-weight: 700;
}

.alertIcon {
    margin: 8px 0 20px;
}

.link {
    display: block;
    margin: 4px 0 12px;
    color: #4a5056;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}
