.closePopup {
    position: absolute;
    top: 12px;
    right: 20px;
    width: 24px;
    height: 24px;
}

.closePopup:before,
.closePopup:after {
    content: '';
    position: absolute;
    width: 24px;
    top: 50%;
    left: 50%;
    height: 2px;
    background-color: var(--border-default);
}

.closePopup:before {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.closePopup:after {
    transform: translate(-50%, -50%) rotate(45deg);
}

.closePopupFixed {
    position: fixed;
}

.closePopup__small {
    top: 11px;
    right: 18px;
    width: 14.3px;
    height: 14.3px;
}

.closePopup__small:before,
.closePopup__small:after {
    width: 20.2px;
    height: 3px;
    border-radius: 5px;
}

.closePopup__left {
    top: 11px;
    left: 18px;
    right: unset;
}

.notificationModal__close {
    top: 17px;
    right: 17px;
    width: 14px;
    height: 14px;
}

.notificationModal__close:before,
.notificationModal__close:after {
    width: 20px;
}
