.logo {
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 1.2px;
    color: var(--border-default);
}

.logo.is-onboarding {
    height: 20px;
    font-size: 12px;
}

.logoImgDefault {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 5px;
}

.logoImgOnboarding {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 5px;
}

.centered {
    margin: 0 auto;
}
