.mb-4 {
    margin-bottom: 4px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-28 {
    margin-bottom: 28px !important;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mb-36 {
    margin-bottom: 36px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-48 {
    margin-bottom: 48px !important;
}

.mb-56 {
    margin-bottom: 56px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.my-s {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}

.my-xs {
    margin-top: 8px;
    margin-bottom: 4px;
}

.my-m {
    margin-top: 16px;
    margin-bottom: 32px;
}

.mt-a {
    margin-top: auto;
}

.colorBrand {
    color: var(--primary-default) !important;
}

.colorMale {
    color: var(--secondary-default) !important;
}

.colorFasting {
    color: var(--logo-color-fast) !important;
}

.colorError {
    color: var(--critical-default) !important;
}

.colorSuccess {
    color: var(--success-default) !important;
}

.colorPromo {
    color: var(--discount) !important;
}

.colorTextSecondary {
    color: var(--text-secondary-subdued) !important;
}

.colorTextMainSecondary {
    color: var(--text-main) !important;
}

.colorTextStrokeSecondary {
    color: var(--primary-active) !important;
}

.centered {
    text-align: center !important;
}

.rightCentered {
    text-align: end !important;
}

.lineThrough {
    text-decoration: line-through;
}

.underline {
    text-decoration: underline;
}

.capitalize {
    text-transform: capitalize;
}

.colorMainSecondary {
    color: var(--text-main) !important;
}

.overlay {
    background: rgba(255 255 255 / 80%);
    position: absolute;
    inset: 0;
    z-index: 999;
}
