.container {
    height: 100%;
    box-sizing: border-box;
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    max-width: 432px;
    padding: 24px;
    width: 100%;
}

.icon {
    font-size: 40px;
    line-height: 52px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.subtitle {
    line-height: 22px !important;
}

.buttonTryAgain {
    height: 64px;
    border: 1px solid var(--border-default);
}

.buttonTryAgain:disabled {
    background-color: var(--surface-main) !important;
}

.buttonTryAgain:active {
    background-color: var(--surface-main) !important;
}

.buttonContactSupport {
    margin: 0 auto;
    max-width: unset !important;
}

.buttonContactSupport:disabled {
    background-color: var(--surface-main) !important;
}

.buttonContactSupport:active {
    background-color: var(--surface-main) !important;
}

.buttonContactSupport > p {
    font-size: 12px;
    color: #4a5056 !important;
}
