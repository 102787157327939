.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;
}

.icon {
    margin: -32px auto 0;
    width: 327px;
}
