.customModal {
    padding: 0;
    max-width: 432px;
    width: calc(100% - 32px);
}

.customOverlay {
    /* multiple modals can be opened at the same time, so we need to set the z-index to the highest possible value */
    z-index: 2147483647 !important;
}

.networkIcon {
    font-size: 40px;
    line-height: 52px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.subtitle {
    line-height: 22px !important;
}

.customButton {
    height: 64px;
    border: 1px solid var(--border-default);
}

.customButton:disabled {
    background-color: var(--surface-main) !important;
}

.customButton:active {
    background-color: var(--surface-main) !important;
}
