.ReactModal__Content {
    position: absolute;
    background: var(--surface-main);
    overflow: auto;
    outline: none;
    padding: 16px;
    border: none;
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    width: 100%;
}

.ReactModal__Content__withTransparency {
    top: 56px;
    border-radius: 12px 12px 0 0 !important;
}

.ReactModal__Content__withPopover {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    margin: auto;
    width: calc(100% - 32px);
    border-radius: 12px !important;
}

.ReactModal__Overlay {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    inset: 0;
    background-color: rgba(255 255 255 / 75%);
    transition: 0.3s;
}

.ReactModal__Overlay__isClose {
    opacity: 0;
    transform: translate(0, 20px);
    visibility: hidden;
}

.ReactModal__Overlay__isOpen {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
}

.ReactModal__Overlay__withTransparency {
    background: rgba(0 0 0 / 50%) !important;
}

.ReactModal__Content__exclusiveOffer {
    padding: 0 !important;
}

.ReactModal__Content__paddingTop32 {
    padding-top: 32px;
}

.ReactModal__Content__paddingTop40 {
    padding-top: 40px;
}

.ReactModal__Content__paddingTop78 {
    padding-top: 78px;
}

.ReactModal__Content__noOverflow {
    overflow: hidden;
}

.ReactModal__Content__flex {
    display: flex;
    flex-direction: column;
}

.ReactModal__Content__contentSize {
    top: 50%;
    width: auto;
    height: fit-content;
    transform: translate(0, -50%);
    margin: var(--default-indent);
    border-radius: 12px !important;
}

@media screen and (min-width: 432px) {
    .ReactModal__Content__checkoutModal {
        top: 50%;
        right: unset;
        bottom: unset;
        left: 50%;
        width: 432px;
        border-radius: 12px !important;
        transform: translate(-50%, -50%);
    }
}

@media screen and (min-width: 768px) {
    /*TODO: in some modals we don't need it (payment-D1-checkout-Charlie1). Check styles, ask for desktop design*/
    .ReactModal__Content__desktop {
        top: 50%;
        right: unset;
        bottom: unset;
        left: 50%;
        width: 375px;
        border-radius: 12px !important;
        transform: translate(-50%, -50%);
    }

    .ReactModal__Content__deskop__w432 {
        width: 432px;
    }

    .ReactModal__Content__contentSize {
        margin: 0;
    }
}
