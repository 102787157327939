.default {
    font-weight: 500;
    color: var(--text-secondary);
}

.medium {
    font-size: 18px;
    line-height: 28px;
}

.extraSmall {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

/*TODO: designers asked to add it because they  use it often*/
.small3 {
    font-size: 12px;
    line-height: 24px;
}

.mediumSmall {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.mediumSmall2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.small {
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
}

.caption {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.small2 {
    font-size: 16px;
    line-height: 24px;
}

.bold {
    font-weight: 700;
}

.normal {
    font-weight: 400;
}

.grey {
    color: var(--text-secondary-subdued) !important;
}

.white {
    color: var(--surface-main);
}

.centered {
    text-align: center;
}

.transparence {
    opacity: 0.8;
}

.colorFast {
    color: var(--logo-color-fast);
}

.withGreyBg {
    background-color: var(--surface-secondary);
    padding: 12px 8px;
    border-radius: 12px;
}

.uppercase {
    text-transform: uppercase;
}

.letterSpacing1 {
    letter-spacing: 1px;
}

.letterSpacing2 {
    letter-spacing: 2px;
}

.safeLight {
    color: var(--safe);
}

.underlineChild a {
    text-decoration: underline;
}

.red {
    color: var(--discount);
}

.orange {
    color: var(--secondary-default);
}

.baseColor {
    color: var(--primary-default);
}

.lineThrough {
    text-decoration: line-through;
}
