.logo {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    height: 28px;
    text-align: center;
    color: var(--border-default);
}

.logo svg {
    height: 28px;
}

.logo.isOnboarding {
    height: 20px;
}

.logoImgDefault {
    max-width: 24px;
    max-height: 24px;
    margin-right: 5px;
    border-radius: 5px;
}

.logoImgDefault svg {
    width: 28px;
    height: 28px;
}

.logoImgOnboarding {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 5px;
}

.centered {
    margin: 0 auto;
}

.logoText {
    font-size: 15px !important;
    color: #4a5056;
}

.biggerLogoText {
    font-size: 17px !important;
    line-height: 17px !important;
}
