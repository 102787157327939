.default {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
}

.default:disabled {
    pointer-events: none;
}

.colorRed {
    color: red;
}

.primary {
    color: var(--surface-main);
    background-color: var(--primary-default);
}

.male {
    color: var(--surface-main);
    background-color: #e73939;
}

.primary:disabled {
    background-color: var(--primary-disabled);
}

.inverse {
    color: var(--primary-default);
    border: 2px solid var(--primary-default);
}

.inverse:disabled {
    color: var(--primary-disabled);
    border: 2px solid var(--border-default);
}

.rounded {
    border-radius: 12px;
}

.iconed {
    position: relative;
    width: 100%;
    max-width: 165px;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    padding: 0 45px 0 16px;
}

.small {
    font-size: 12px;
    line-height: 16px;
    max-width: 138px;
    padding: 4px 0;
    height: 40px;
}

.bold {
    background-color: transparent;
    color: var(--primary-default);
}

.female {
    color: var(--surface-main);
    background-color: #7e97f1;
}

.animation {
    width: calc(100% - 20px);
    animation: pulsar 2s infinite;
    margin: 0 auto;
    display: block;
    position: relative;
}

@keyframes pulsar {
    0% {
        box-shadow: 0 0 0 0 var(--primary-default);
    }

    70% {
        box-shadow: 0 0 0 14px var(--primary-default);
    }

    100% {
        box-shadow: 0 0 0 14px transparent;
    }
}

.isOnePrice {
    margin-bottom: 20px;
}

.btnConsent {
    max-width: 200px;
    letter-spacing: 1px;
}
